
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
// components
import list from "@/components/common/list.vue";
import Notice from "@/components/message/Notice.vue";
import Letter from "@/components/message/Letter.vue";
import Comment from "@/components/message/Comment.vue";
import Support from "@/components/message/Support.vue";
import MenuTab from "@/components/common/MenuTab.vue";

export default defineComponent({
  name: "News",
  components: {
    list,
    Notice,
    Letter,
    Comment,
    Support,
    MenuTab,
  },
  setup() {
    //  切换列表菜单
    type Menu = "通知" | "私信" | "评论" | "点赞";
    const menu: Menu[] = ["通知", "私信", "评论", "点赞"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    return {
      menu,
      currentMenuItem,
      selectMenu,
    };
  },
});
